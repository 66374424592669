
import { computed, defineComponent, onMounted, PropType, ref } from "vue"
import ApiService from "@/core/services/ApiService"
import { ElForm } from "element-plus"

interface IMenu {
  name: string
  id: string
}
export default defineComponent({
  name: "add-menu-action-modal",
  props: {
    selectedMenu: {
      type: Object as PropType<IMenu>,
      required: true,
    },
  },
  components: {},
  emits: ["onClose", "onAdd"],
  setup(props, { emit }) {
    const selectedCategory = ref(null)
    const selectedAction = ref(null)
    const categoryList = ref(null)
    const actionList = ref(null)

    type FormInstance = InstanceType<typeof ElForm>
    const formRef = ref<FormInstance>()

    const addMenuActionModalRef = ref<null | HTMLElement>(null)
    const loading = ref<boolean>(false)
    const formData = ref({
      actionId: null,
      menuId: null,
    })

    const isMenuSelected = computed(() => props.selectedMenu != null)

    function handleClose(done) {
      emit("onClose")
      done()
    }

    function submitForm(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const config = { ...formData.value, menuId: props.selectedMenu.id }
          await ApiService.post("menu/add-action", config)
          emit("onAdd")
        } else {
          return false
        }
      })
    }

    async function getCategoryList() {
      const { data } = await ApiService.get("action/action-category")
      categoryList.value = data
    }

    async function getActions(actionCategoryId) {
      const { data } = await ApiService.get("action/" + actionCategoryId)
      actionList.value = data
    }

    onMounted(async () => {
      await getCategoryList()
    })

    return {
      handleClose,
      isMenuSelected,
      selectedCategory,
      selectedAction,
      formData,
      categoryList: categoryList,
      actionList,
      submitForm,
      formRef,
      loading,
      addMenuActionModalRef,
      getActions,
    }
  },
})
