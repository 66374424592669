
import { InfoFilled } from "@element-plus/icons-vue"
import { defineComponent, ref, onMounted } from "vue"
import AddMenuActionModal from "@/components/modals/forms/AddMenuActionModal.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  name: "menu-action",
  components: {
    AddMenuActionModal,
  },
  setup() {
    const tableData = ref(null)
    const selectedMenu = ref(null)
    const isAddModelVisible = ref(false)

    async function getMenuActions() {
      const { data } = await ApiService.get("menu/with-actions")
      tableData.value = data
    }

    function onAdd() {
      setSelectedMenu(null)
      getMenuActions()
    }

    async function removeAction(id) {
      await ApiService.get("menu/remove-action/" + id)
      await getMenuActions()
    }

    function setSelectedMenu(menu) {
      isAddModelVisible.value = menu != null
      selectedMenu.value = menu
    }

    const tableHeader = ref([
      {
        name: "Menü",
        key: "name",
        sortable: false,
      },
      {
        name: "İşlemler",
        key: "menuActions",
      },
      {
        name: "Ekle",
        key: "actions",
      },
    ])

    onMounted(async () => {
      await getMenuActions()
      setCurrentPageBreadcrumbs("Customers Listing", ["Apps", "Customers"])
    })

    return {
      onAdd,
      removeAction,
      getMenuActions,
      isAddModelVisible,
      tableData,
      tableHeader,
      selectedMenu,
      setSelectedMenu,
      InfoFilled,
    }
  },
})
